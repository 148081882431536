<template>
    <div id="home">
        <HomeVideo />
        <div class="stats">
            <h2>
                Paul Salazar Group Stats
            </h2>
            <div class="stats-container">
                <div class="stat">
                    <ICountUp
                        :delay=300
                        :endVal=19
                        :options="options1"
                        class="countup"
                    />
                    <h3 class="unit">
                        Years
                    </h3>
                    <p class="area">
                        In real estate
                    </p>
                </div>
                <div class="stat">
                    <ICountUp
                        :delay=300
                        :endVal=750
                        :options="options2"
                        class="countup"
                    />
                    <h3 class="unit">
                        Million
                    </h3>
                    <p class="area">
                        In sales
                    </p>
                </div>
                <div class="stat">
                    <ICountUp
                        :delay=300
                        :endVal=250
                        :options="options3"
                        class="countup"
                    />
                    <h3 class="unit">
                        Clients
                    </h3>
                    <p class="area">
                        SERVED
                    </p>
                </div>
            </div>
        </div>
        <div class="biography">
            <img src="/images/paul.jpg" alt="Paul Salazar">
            <div class="biography-content">
                <div class="title">
                    <div class="title-section">
                        <h1 class="first-name">
                            Paul
                        </h1>
                        <hr>
                        </div>
                        <h1 class="last-name">
                            Salazar
                        </h1>
                    </div>
                <div class="content">
                    <p>
                        With over $750 million in total real estate sales and 19 years of experience, Paul Salazar is a seasoned real estate professional known for his deep market knowledge, strategic insight, and ability to deliver results in Los Angeles' most competitive neighborhoods.
                    </p>
                    <router-link to="/about">
                        More
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="featured-properties" v-else>
            <FeaturedProperties v-if="soldProperties.length > 0" align="left" title="Past Sales" :data="soldProperties" :bgColor="'#000'"/>
            <FeaturedProperties v-if="availableProperties.length > 0" align="right" title="Exclusive Listings" :data="availableProperties" :bgColor="'#1a1a1a'"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import HomeVideo from '../components/HomeVideo'
import FeaturedProperties from "../components/FeaturedProperties"
import Spinner from 'vue-simple-spinner'
import lazyLoadComponent from '../utils/lazy-load-component';
import SkeletonBox from '../components/SkeletonBox';

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
    name: "Home",
    components: {
        HomeVideo,
        FeaturedProperties,
        Spinner,
        ICountUp: lazyLoadComponent({
            ...defaultOptions,
            componentFactory: () => import('vue-countup-v2'),
        }),
    },
    metaInfo: {
        title: 'Paul Salazar',
        meta: [{
            name: 'description',
            content: "Paul Salazar"
        }]
    },
    data(){
        return{
            loading: true,
            options1: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '$',
                suffix: ''
            },
            options3: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '+'
            }
        }
    },
    mounted:function(){
        this.axios.get('https://strapi.uptowncreative.io/paul-salazar-properties')
        .then((response) => {
            this.data = response.data;
            this.loading = false;
        })
    },
    computed: {
        soldProperties:function(){
            var temp = this.data.filter((item) => {
                return item.Tag === 'sold' || item.Tag ==='past';
            }).sort((a, b) => {
               return b.Price - a.Price;
            }).slice(0,10);
            return temp;
        },
        availableProperties: function(){
            return this.data.filter((item) => {
                return item.Tag === 'available';
            }).sort((a, b) => {
               return b.Price - a.Price;
            }).slice(0,10);
        }
    },
}
</script>

<style lang="scss" scoped>
.stats{
    color: #fff;
    text-transform: uppercase;
    margin: 4rem 0;
    h2{
        font-weight: 600;
        text-align: center;
        font-size: 13px;
        letter-spacing: 3px;
        margin: 2rem auto;
    }
    &-container{
        display: flex;
        justify-content: center;
        align-items: center;
        .stat{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 33.33%;
            border-right: 1px solid rgba(255, 255, 255, 0.25);
            &:last-child{
                border-right: none;
            }
            .countup{
                font-size: 75px;
                font-family: "karmina",serif;
                font-style: italic;
                margin-bottom: 0;
            }
            .unit{
                font-size: 18px;
                letter-spacing: 3px;
                font-weight: 500;
                margin: 0;
            }
            .area{
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 3px;
                opacity: 0.25;
                font-style: 13px;
            }
        }
    }
}

.biography{
    color: #fff;
    background: #1a1a1a;
    padding: 6rem 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        display:block;
        width: 50%;
        height: auto;
        padding-right: 10%;
    }
    &-content{
        display:flex;
        flex-direction: column;
        flex: 1 1;
        .title{
            h1{
                font-weight: 100;
                letter-spacing: 2px;
                font-size: 65px;
                text-transform: uppercase;
                margin: 0;
                margin-right: 3px;
            }
            .first-name{
                margin: 0;
                margin-top: 10px;
            }
            .last-name{
                margin-top: -50px;
            }
            hr{
                display: block;
                height: 1px;
                border: 0;
                width: calc(100% - 156px);
                border-top: 1px solid rgba(255,255,255,0.25);
            }
            &-section{
                display: flex;
                align-items: center;
            }
        }
    }
    .content{
        a{
            display: inline-block;
            margin-top: 2rem;
            text-transform: uppercase;
            background: #1a1a1a;
            padding: 10px 30px;
            border: 1px solid rgba(255,255,255,0.25);
            letter-spacing: 3px;
            font-size: 13px;
            font-weight: 600;
        }
    }
}
@media (max-width: $mobile) {
    .stats-container{
        flex-direction: column;
        .stat{
            width: 100%;
            border: none;
        }
    }
    .biography{
        flex-direction: column;
        padding: 4rem 0;
        img{
            width: 100%;
            padding: 0;
        }
        .biography-content{
            padding: 2rem;
        }
        .content{
            display: flex;
            flex-direction: column;
            a{
                margin: 0 auto;
                align-self: center;
            }
        }
    }
}
</style>
